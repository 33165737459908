





















































import { Component, Vue } from "vue-property-decorator";
import { Profile } from "@/data/profile";
import ProfileResource from "@/resources/profile-resource";
import { Action, Getter } from "vuex-class";

@Component
export default class ProfileView extends Vue {
  form: Profile = new Profile();
  edit: boolean = false;
  retryTask: any = null;
  @Action updateAccount: () => Promise<void>;
  @Getter isAccountBanned?: boolean;
  @Getter isAccountActive?: boolean;

  created() {
    this.reloadAccount();
    this.form = new Profile(this.$store.state.account.profile);
    this.retryTask = setInterval(() => {
      this.reloadAccount();
    }, 10000);
  }
  beforeDestroy() {
    if (this.retryTask) {
      clearInterval(this.retryTask);
    }
  }

  async reloadAccount(): Promise<void> {
    const isAccountActive = this.isAccountActive;
    await this.updateAccount().then(() => {
      this.$nextTick(() => {
        if (isAccountActive !== this.isAccountActive && !this.isAccountBanned) {
          this.$router.push({
            name: "wallet-operations-list"
          });
        }
      });
    });
  }

  get notification(): any {
    if (this.isAccountBanned) {
      return {
        message: "Вы были заблокированы",
        type: 0
      };
    }
    if (!this.isAccountActive) {
      return {
        message:
          "Мы проверяем ваши данные и в скором времени вы сможете начать работу с кошельком",
        type: 1
      };
    }
    return null;
  }
}
